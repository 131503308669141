#sb-title-inner, #sb-info-inner, #sb-loading-inner, div.sb-message {
    font-family:"HelveticaNeue-Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight:200;
    color:#fff;
}
#sb-container {
    position:fixed;
    margin:0;
    padding:0;
    top:0;
    left:0;
    z-index:9999;
    text-align:left;
    visibility:hidden;
    display:none;
}
#sb-overlay {
    position:relative;
    height:100%;
    width:100%;
    background: #3a3f51;
    opacity: 0.8;
    filter: alpha(opacity=80);
}
#sb-wrapper {
    position:absolute;
    visibility:hidden;
    width:100px;
}
#sb-wrapper-inner {
    position:relative;
    overflow:hidden;
    height:100px;
    padding: 0px;
    border-radius: 0px;
    -webkit-box-shadow: 0 0 9px -4px #000000;
    -moz-box-shadow: 0 0 9px -4px #000000;
    box-shadow: 0 0 9px -4px #000000;
}
#sb-body {
    position:relative;
    height:100%;
}
#sb-body-inner {
    position:absolute;
    height:100%;
    width:100%;
}
#sb-player.html {
    height:100%;
    overflow:auto;
}
#sb-body img {
    border:none;
}
#sb-loading {
    position:relative;
    height:100%;
}
#sb-loading-inner {
    position:absolute;
    font-size:14px;
    line-height:50px;
    height:50px;
    top:50%;
    margin-top:-12px;
    width:100%;
    text-align:center;
}
#sb-loading-inner span {
    background:url(shadowbox/ajax-loading.gif) no-repeat;
    padding-left:50px;
    display:inline-block;
}
#sb-body, #sb-loading {
    background-color:#FFFFFF;
}
#sb-title, #sb-info {
    position: absolute;
    margin:0;
    padding:0;
    overflow:hidden;
}
#sb-title, #sb-title-inner {
    height:26px;
    line-height:26px;
}
#sb-title-inner {
    font-size:16px;
}
#sb-info, #sb-info-inner {
    height: 30px;
    line-height: 30px;
    top: 15px;
    right: 25px;
}
#sb-info-inner {
    font-size:12px;
}
#sb-nav {
    float:right;
    height: 24px;
    padding:2px 0;
    width:45%;
}
#sb-nav a {
    display:block;
    float:right;
    height: 24px;
    width: 24px;
    margin-left:3px;
    cursor:pointer;
    background-repeat:no-repeat;
}
#sb-nav-close {
    background-image:url(shadowbox/close.png);
}
#sb-nav-next {
    background-image:url(shadowbox/next.png);
}
#sb-nav-previous {
    background-image:url(shadowbox/previous.png);
}
#sb-nav-play {
    background-image:url(shadowbox/play.png);
}
#sb-nav-pause {
    background-image:url(shadowbox/pause.png);
}
#sb-counter {
    float:left;
    width:45%;
}
#sb-counter a {
    padding:0 4px 0 0;
    text-decoration:none;
    cursor:pointer;
    color:#fff;
}
#sb-counter a.sb-counter-current {
    text-decoration:underline;
}
div.sb-message {
    font-size:12px;
    padding:10px;
    text-align:center;
}
div.sb-message a:link, div.sb-message a:visited {
    color:#fff;
    text-decoration:underline;
}
